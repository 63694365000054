import * as React from "react";
import * as ReactDOM from "react-dom";

/** style */
import "./showhead.scss";

/**
 * prop
 */
interface BaseProps {
    sourcedata: any;
    pincolor: any;
    eventchanges: any;
}
/**
 * date of state on datas
 */
interface BaseState {}

export class ShowSingleHead extends React.Component<BaseProps, BaseState> {
    state: BaseState = {};

    public componentDidMount() {}
    // public componentWillReceiveProps() {}
    public componentDidUpdate() {}

    public render() {
        const pincolor = {
            color: this.props.pincolor,
        };
        return (
            <div className="showminicalender2">
                <div className="clist">
                    <div className="cheader" onClick={this.props.eventchanges} >
                        <div className="carea" style={pincolor}>
                            {this.props.sourcedata.names}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
