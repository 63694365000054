import * as React from "react";
import * as ReactDOM from "react-dom";

/** style */
import "./showsingle2.scss";

/**
 * prop
 */
interface BaseProps {
    sourcedata: any;
    pincolor:any;
}
/**
 * date of state on datas
 */
interface BaseState {}

export class ShowSingle extends React.Component<BaseProps, BaseState> {
    state: BaseState = {};

    public componentDidMount() {}
    // public componentWillReceiveProps() {}
    public componentDidUpdate() {}

    public render() {
        const PreviewComponent = this.props.sourcedata.datas.map(
            (v: any, i: any) => {

                let youbicolor = {color:"#fff"};
                if(v.youbi == "日"){
                    youbicolor = {color:"#ffa9fe"};
                }else if(v.youbi == "土"){
                    youbicolor = {color:"#75e5ff"};
                }

                let incontent;
                if(v.content ==""){
                    incontent = "対応エリア最短即日！"
                }else{
                    incontent = v.content;
                }

                return (
                    <div key={i} className="ccard">
                        <div className="cdate">
                            <span style={youbicolor} >
                            {v.date}({v.youbi})
                            </span>
                        </div>
                        <div className="ccontent">{incontent}</div>
                    </div>
                );
            }
        );

        const pincolor = {
            color: this.props.pincolor
        }
        return (
            <div className="showminicalender">
                <div className="clist">
                    <div className="cheader">
                        <div className="pin icon" style={pincolor}></div>
                        <div className="carea" style={pincolor}>{this.props.sourcedata.names}</div>
                        <div className="csubscription">
                            {this.props.sourcedata.header_message}
                        </div>
                    </div>
                    <div className="cbody">
                        {PreviewComponent}
                    </div>
                    <div className="cfooter">
                        <div className="ctempo">
                            {this.props.sourcedata.tempo}
                        </div>
                        <div className="cftel">
                            <a href="tel:{this.props.sourcedata.tel}">TEL: {this.props.sourcedata.tel}</a>
                        </div>
                        <div className="cfsubscription">
                            <span dangerouslySetInnerHTML={{__html: this.props.sourcedata.footer_message}} >
                            </span>
                        </div>
                        <div className="cfeigyoujikan">
                            {this.props.sourcedata.accept_time}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
