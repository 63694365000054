import * as React from "react";
import * as ReactDom from "react-dom";

import "./showall2.scss";

import { ShowSingle } from "./showsingle2";
import { ShowSingleHead } from "./showsingle2head";

/**
 * redux
 */
import reducer from "./showreducers";

import { Provider, connect } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import {
    select,
    call,
    put,
    takeEvery,
    takeLatest,
    delay,
    all,
} from "redux-saga/effects";

function getTodayDate() {
    //日付オブジェクトを作成する
    var dd = new Date();
    //「年」を取得する
    var YYYY = dd.getFullYear();
    //「月」を取得する
    var MM = dd.getMonth() + 1;
    //「日」を取得する
    var DD = dd.getDate();
    //段落タグ("date011")の中身を置き換える
    var pelem011 = document.getElementById("date011");
    return MM + "月" + DD + "日";
}

function set2fig(num: any) {
    // 桁数が1桁だったら先頭に0を加えて2桁に調整する
    var ret;
    if (num < 10) {
        ret = "0" + num;
    } else {
        ret = num;
    }
    return ret;
}
function showClock() {
    var nowTime = new Date();
    var nowHour = set2fig(nowTime.getHours());
    var nowMin = set2fig(nowTime.getMinutes());
    var nowSec = set2fig(nowTime.getSeconds());
    var msg = nowHour + ":" + nowMin + ":" + nowSec;

    return msg;
}

/**
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 */
function getParam(name: any) {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function ceckLocal() {
    const url = window.location.href;

    let string = url;
    let regexp = RegExp("localhost", "g");
    let result = regexp.test(string);
    console.log("result is ", result);

    if (result == true) {
        return "/storage/all.json";
    } else {
        return "https://minicalender.yamato-gp.net/storage/all.json";
    }
}

function* AjaxGetPost(url: any) {
    /**
     * ajax func
     *
     * https://column.yamato-gp.net/get_json.php?limit=3
     *
     */
    const retvalue = yield call(() => {
        const callurl = ceckLocal();
        try {
            return fetch(callurl)
                .then((res) => res.json())
                .then((html) => {
                    return { count: html.length, data: html };
                });
        } catch (e) {
            return { count: "0" };
        }
    });
    yield put({ type: "ajax_get_post", volume: retvalue });
}

function* TestClick(v: any) {
    yield put({ type: "test", volume: v });
}

/**
 * 3-2 setting Mysaga
 */
function* MySaga() {
    yield all([
        takeLatest("Ajax_Get_Post", AjaxGetPost),
        takeLatest("Test_Click", TestClick),
    ]);
}

/**
 * state
 */
interface BaseState {
    currentCalender: any;
    maxItems: any;
    now: any;
}

/**
 *
 *
 *
 */
class BaseAppShow extends React.Component<any, BaseState> {
    state: BaseState = {
        currentCalender: 0,
        maxItems: 0,
        now: showClock(),
    };

    public intervalId: any;

    public componentDidMount() {
        this.props.dispatch({ type: "Ajax_Get_Post", url: "test2" });

        this.intervalId = setInterval(() => {
            this.setState({
                now: showClock(),
            });
        }, 1000);
    }
    public componentWillReceiveProps() {}
    public componentDidUpdate() {}

    public componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    public changelist = (i: any) => {
        this.setState({
            currentCalender: i,
        });
        console.log("i is ", this.props.todos.count);
    };

    public changelist2 = (i: any) => {
        let tempNum = Number(this.state.currentCalender) - Number(i);

        if (tempNum < 0) {
            tempNum = 0;
        } else if (tempNum > ( Number(this.props.todos.count)-1) ) {
            tempNum = Number(this.props.todos.count) -1;
        }

        this.setState({
            currentCalender: tempNum,
        });
        //console.log("i is ", i);
    };
    /**
     * component render
     */
    public render() {
        //console.log("props is ",this.props.todos);

        // パラメータから取得する形式の場合
        // これは一旦廃止
        const kdom = document.getElementById("laravel_app_05");
        const requestparam = kdom.getAttribute("data-area");

        //console.log("request area is ", requestparam);

        const showComponentHead =
            this.props.todos.data != null ? (
                this.props.todos.data.map((v: any, i: any) => {
                    const jsond = JSON.parse(v.draft_data || "null");

                    let tempclassname = "";
                    if (this.state.currentCalender == i) {
                        tempclassname = "minicalender20200405flexbox2a";
                    } else {
                        tempclassname = "minicalender20200405flexbox2b";
                    }

                    return (
                        <div className={tempclassname} key={i}>
                            <ShowSingleHead
                                sourcedata={jsond}
                                pincolor={jsond.pincolor}
                                eventchanges={() => this.changelist(i)}
                            />
                        </div>
                    );
                })
            ) : (
                <p>now loading・・・</p>
            );

        const showComponent =
            this.props.todos.data != null ? (
                this.props.todos.data.map((v: any, i: any) => {
                    if (this.state.currentCalender == i) {
                        const jsond = JSON.parse(v.draft_data || "null");
                        return (
                            <span
                                className="minicalender20200405flexbox"
                                key={i}
                            >
                                <ShowSingle
                                    sourcedata={jsond}
                                    pincolor={jsond.pincolor}
                                />
                            </span>
                        );
                    }
                })
            ) : (
                <p>now loading・・・</p>
            );

        /**
         *
         */
        return (
            <div className="tempostylebox20200417">
                <div className="allparts">
                    <img src="https://minicalender.yamato-gp.net/img/th01.png" alt="" />
                </div>
                <div className="leftparts">
                    <img className="tizu" src="https://minicalender.yamato-gp.net/img/th02.png" alt="" />
                    <div className="navibox">
                        <div
                            className="headerbox aka"
                            onClick={() => this.changelist2(1)}
                        >
                            エリアを選択
                        </div>
                        <div className="centerbox">{showComponentHead}</div>
                        <div
                            className="footerbox aka"
                            onClick={() => this.changelist2(-1)}
                        >
                            ▼
                        </div>
                    </div>
                </div>
                <div className="rightparts">
                    <div className="clock-h9m0 icon"></div>
                    <div className="days">
                        <span className="hiduke">{getTodayDate()}</span>
                        <span className="jikan">{this.state.now}</span>
                    </div>
                    <div className="setumei">最短即日お伺い！！</div>
                    <div className="tempostyle20200417">{showComponent}</div>
                </div>
                <div className="allparts">
                    <img src="https://minicalender.yamato-gp.net/img/th03.png" alt="" />
                </div>
            </div>
        );
    }
}

/**
 * 1-3 set App
 */
const App: any = (() => {
    const mapStatetoProps = (state: any) => {
        return { ...state };
    };
    return connect(mapStatetoProps)(BaseAppShow);
})();

/**
 * 3-4 sagaMiddleware
 */
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(MySaga);

/**
 * 1-4 set ReactDom.render
 */

ReactDom.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("laravel_app_05")
);

/**
 *
 */
//ReactDOM.render(<BaseAppShow />, document.getElementById("laravel_app_03"));
